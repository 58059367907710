import { combineReducers } from 'redux';
import { configureStore } from '@reduxjs/toolkit'
// import counterReducer from '../slices/counter'

import { setupListeners } from '@reduxjs/toolkit/query'
import counterReducer from '../slices/counter';

import { authApi } from '../api/auth';
import { locationApi } from '../api/location';
import googleAuthReducer from '../slices/googleAuth';
import deeplinkReducer from '../slices/deeplink';
import userReducer from '../slices/user';
import locationReducer from '../slices/locations';

// import { getDefaultMiddleware } from '@reduxjs/toolkit';

const reducers = combineReducers({
  [authApi.reducerPath]: authApi.reducer,
  [locationApi.reducerPath]: locationApi.reducer,

  counter: counterReducer,
  googleAuth: googleAuthReducer,
  deeplink: deeplinkReducer,
  user: userReducer,
  location: locationReducer,
//   [dataApi.reducerPath]: dataApi.reducer,
//   [locationApi.reducerPath]: locationApi.reducer,
//   [authApi.reducerPath]: authApi.reducer,

//   counter: counterReducer,
//   transcode: transCodeVideoReducer,
//   preprocessed: preprocessedVideoReducer,
//   auth: authReducer,
})

const store = configureStore({
  reducer: reducers,


  // {
    // posts: postsReducer,
    // comments: commentsReducer,
    // users: usersReducer,
    // counter: counterReducer,
  // },
  // middleware: (getDefaultMiddleware) =>
  //   getDefaultMiddleware({
  //     // thunk: {
  //     //   extraArgument: myCustomApiService,
  //     // },
  //     // 將 serializableCheck 關閉的原因，是因為想要在 store 裏儲存 class 型態的物件
  //     // 避免指使用 JS 的純 object (EX: `{}`)
  //     // 想要想勇 TS 強行太檢查的好處
  //     serializableCheck: false,
  //   }),
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(authApi.middleware),
})

// Source: https://redux-toolkit.js.org/rtk-query/overview
// optional, but required for refetchOnFocus/refetchOnReconnect behaviors
// see `setupListeners` docs - takes an optional callback as the 2nd arg for customization
// 應該是為了讓 `refetchOnFocus`、`refetchOnReconnect` 生效，所以要讓 RTX 拿到 window 的 listener
// 方便在 window 拿到 focus 時觸發 refetch
// setupListeners(store.dispatch)

export default store;

console.log("Store: ", store);

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch
