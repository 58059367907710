import * as React from 'react'

import { ILocation } from "../../models/location";

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { Paper } from '@material-ui/core';

interface Props {
  locations: ILocation[]
}

export function LocationTable(props:Props) {

  return(
    <TableContainer component={Paper}>
    <Table size="small" aria-label="a dense table">
        <TableHead>
        <TableRow>
            <TableCell>時間</TableCell>
            <TableCell align="right">緯度</TableCell>
            <TableCell align="right">經度</TableCell>
        </TableRow>
        </TableHead>

        <TableBody>
        {props.locations.map((row:any) => (
            <TableRow key={row.id}>
            <TableCell component="th" scope="row">
                {row.dateTime}
            </TableCell>
            <TableCell align="right">{row.lat}</TableCell>
            <TableCell align="right">{row.lng}</TableCell>
            </TableRow>
        ))}
        </TableBody>
    </Table>
    </TableContainer>
  )
}