import './App.css';
import React, {useState, useEffect} from 'react';
import {
  HashRouter,
  Switch,
  Route,
  Link,
  useLocation,
} from "react-router-dom";

import {useHistory} from "react-router-dom";

// import { Login, Logout, useAuth } from './login';

import { Button } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import MenuIcon from '@material-ui/icons/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import AccountCircle from '@material-ui/icons/AccountCircle';
import Avatar from '@material-ui/core/Avatar';

// import { useGoogleLogin } from 'react-use-googlelogin'
import { GoogleLogin, GoogleLogout, useGoogleLogin, useGoogleLogout} from 'react-google-login';

import queryString from 'query-string'

import { useLoginMutation } from './redux/api/auth';
import { setUserInfo, clearUserInfo, selectUser, IUser } from './redux/slices/user';

import { useAppDispatch, useAppSelector } from './redux/store/hook';
import { Fullscreen } from '@material-ui/icons';
import { triggerOpen } from './redux/slices/deeplink';

import { MyLocation } from './component/location/MyLocation';




function NavUserProfileButton(props) {

  const [anchorElement, setAnchorElement] = useState(null)

  const currentUser = useAppSelector(selectUser)
  console.log(`dump current user: `, currentUser)

  const handleClick = (event) => {
    setAnchorElement(event.currentTarget)
  };

  const handleClose = (event) => {
    console.log('handleClose ...')
    setAnchorElement(null)
  };
  
  const handleCloseWithFunc = (event, myFunc) => {
    console.log('handleClose w myFunc ...', event)
    console.log('handleClose w myFunc ... func:', myFunc)
    myFunc()
    setAnchorElement(null)
  };

  return (
    <div>
      <IconButton
        edge="end"
        color="inherit"
        onClick={handleClick}
      >
        { currentUser.authID != ""
          ? <Avatar alt="Profile" src={currentUser.profilePicture} />
          : <AccountCircle />
        }
      </IconButton>

      <HashRouter>

        <Menu
          id="simple-menu"
          anchorEl={anchorElement}
          open={Boolean(anchorElement)}
          onClose={handleClose}
        >
          {/* { currentUser.authID != ""
            ? <MenuItem component={Link} to="/logout" onClick={handleClose}>登出</MenuItem>
            : <MenuItem component={Link} to="/login" onClick={handleLogin}>登入</MenuItem>
          } */}

          { currentUser.authID != ""
            ? <MenuItem onClick={(e) => handleCloseWithFunc(e, props.handleGoogleSignOut)}>G登出</MenuItem>
            : <MenuItem onClick={(e) => handleCloseWithFunc(e, props.handleGoogleSignIn)}>G登入</MenuItem>
          }
        </Menu>
      </HashRouter>

    </div>
  )

}


function App() {

  // const [user, setUser] = useState({});
  // console.log("App user:", user)
  // const googleAuthRediretURI = 'http://localhost:3000/';
  const googleAuthRediretURI = 'https://online.sanbao.app/';
  
  // 這段是 令人有點失望的 package: react-use-googlelogin
  // const { isSignedIn, isInitialized, googleUser } = useGoogleLogin({
  //   clientId: '1005417081196-4shevc53jvjpvj106c1876vt5shrt7i7.apps.googleusercontent.com',
  //   uxMode: 'redirect',
  //   redirectUri: googleAuthRediretURI,
  // })

  const history = useHistory()

  const dispatch = useAppDispatch()
  const currentUser = useAppSelector(selectUser)
  console.log(`dump current user: `, currentUser)

  const [apiLogin, { isLoading }] = useLoginMutation()
  
  const responseGoogle = (response) => {
    console.log(`你看看這個 Google Response: `, response);
  }

  const doServerSideLogin = (googleResponse) => {

    console.log(`doLogin() Google Response: `, googleResponse);

    apiLogin(googleResponse.tokenId)
    .unwrap()
    .then(
      fulfilled => {
        console.log("fulfilled: ", fulfilled)
        dispatch(setUserInfo({
            email: googleResponse.profileObj.email,
            familyName: googleResponse.profileObj.familyName,
            givenName: googleResponse.profileObj.givenName,
            profilePicture: googleResponse.profileObj.imageUrl,
            name: googleResponse.profileObj.name,
            authID: fulfilled.authID,
          })
        )
        return fulfilled
      }
    )
    .then((apiData) => {
      const myFrom = localStorage.getItem("lf")
      console.log("檢查是否含有 lf 參數: ", myFrom)
      if(myFrom === "sanbao") {
        localStorage.removeItem("lf")

        console.log(`送訊號 with SSO: ${apiData.ssoToken}`)

        window.location.href = "sanbao-dev://login/auth?token="+apiData.ssoToken;
        history.push("/")
      }
    })
    .catch(
      rejected => console.error("rejected: ", rejected)
    )

  }

  // 新嘗試 react-google-login
  const { signIn, loaded } = useGoogleLogin({
    clientId: '1005417081196-4shevc53jvjpvj106c1876vt5shrt7i7.apps.googleusercontent.com',
    uxMode: 'redirect',
    // responseType: 'code',
    accessType: 'offline',
    redirectUri: googleAuthRediretURI,
    onSuccess:doServerSideLogin,
    onFailure:responseGoogle,
    isSignedIn: true, //// 這個看起來是關鍵 ---->
    onAutoLoadFinished: (response) => console.log(`你看看這個 Google Response RRRRR: `, response),
  })

  const { signOut:googleSignOut } = useGoogleLogout({
    clientId: '1005417081196-4shevc53jvjpvj106c1876vt5shrt7i7.apps.googleusercontent.com',
    onLogoutSuccess:(resp) => {
      console.log("signOut!! =>", resp)
      localStorage.clear();
      dispatch(setUserInfo())
    },
    onFailure:responseGoogle,
  })

  const signOut = () => {
    console.log("signOut!!")
    googleSignOut();
    dispatch(clearUserInfo())
  }

  const { search } = useLocation();

  // const [auth, setAuth] = useAuth();
  // const [loginFrom, setLoginFrom] = useState("online");
  
  const loginFrom = queryString.parse(search);
  console.log("哦哦哦哦 parse querystring: ", loginFrom);
  if(loginFrom.lf === "sanbao") {
      console.log("啊啊啊啊啊啊 detect query string tag from 'sanbao', mark in storage");
      localStorage.setItem("lf", "sanbao");
  }

  // const ssoToken = localStorage.getItem("ssoToken");

  const deepLinkTo = useAppSelector((state) => state.deeplink.desktop)
  const deepLinkDesktopSSO = useAppSelector((state) => state.deeplink.ssoToken)
  
  const handleLogin = (isSuccess, data) => {
    // setUser({isLogined: true, authID: data.authID, name: data.name, imageUrl: data.imageUrl});
    // setAuth({isLogined: true, authID: data.authID, name: data.name, imageUrl: data.imageUrl});
    console.log("handleLogin() is working on set auth data")
  }

  const handleLogout = (isSuccess, data) => {
    console.log("clearing local storage data");
    localStorage.clear();
    dispatch(setUserInfo())
    // setAuth({isLogined: false, authID: "", name: "", imageUrl: ""});
  }

  return (
    <div>
      <AppBar position="static">
        <Toolbar>

          <IconButton
            edge="start"
            color="inherit"
          >
            <MenuIcon />
          </IconButton>

          <Typography variant="h6" noWrap>
            SANBAO online
          </Typography>

          <div>
            <NavUserProfileButton handleGoogleSignIn={signIn} handleGoogleSignOut={signOut}></NavUserProfileButton>
          </div>
        </Toolbar>

      </AppBar>
        {/* <nav align="right">
        <Link to="/">首頁</Link>
        { auth.isLogined
          ? <Link to="/logout" style={{marginLeft:"20px"}}>登出</Link>
          : <Link to="/login" style={{marginLeft:"20px"}}>登入</Link>
        }
      </nav> */}
        <Switch>
          {/* <Route path="/login">
            <Login user={auth} handleLogin={handleLogin}></Login>
          </Route> */}

          {/* <Route path="/login/desktop">
            <LoginDesktop user={auth} handleLogin={handleLogin} handleLoginFromApp={handleLoginFromApp}></LoginDesktop>
          </Route> */}

          {/* <Route path="/logout">
            <Logout user={auth} handleLogout={handleLogout}></Logout>
          </Route> */}

          <Route path="/">
            <MyLocation></MyLocation>
          </Route>
        </Switch>
    </div>
  );
}

// function DeepLinkDesktop(props) {

//   console.log(`APP useEffect() 變動偵測, deepLinkTo=${props.deepLinkTo}`)

//   if(props.deepLinkTo === "sanbao") {
//     console.log("APP useEffect() 叫三寶")
//     // const mySsoToken = localStorage.getItem("ssoToken")
//     window.location.href = "sanbao-dev://login/auth?token="+props.ssoToken;
//     window.location.href = "/";
//   }

//   return (<></>)
// }

export default App;
