import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export interface IDeepLink {
  desktop: string
  ssoToken: string
}

const initialState: IDeepLink = {desktop: "", ssoToken: ""};

export const deeplinkSlice = createSlice({
  name: 'deeplink',
  initialState,
  reducers: {
    // triggerOpen: (state, action: PayloadAction<string>) => ({
    //   state = "action.payload"
    // }),
    
    triggerOpen: (state, action: PayloadAction<string>) => ({
      // desktop: action.payload
      desktop: "sanbao",
      ssoToken: action.payload
    }),
  },
})

// Action creators are generated for each case reducer function
// export const { increment, decrement, incrementByAmount } = counterSlice.actions
export const { triggerOpen } = deeplinkSlice.actions

export default deeplinkSlice.reducer