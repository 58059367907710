import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import type { RootState } from '../store/store';
import { ILocation } from '../../models/location';

const initialState: ILocation[] = [];

export const locationSlice = createSlice({
  name: 'location',
  initialState,
  reducers: {
    addLocation: (state, action: PayloadAction<ILocation>) => (
      state.concat(action.payload)
    ),
  },
})

// Action creators are generated for each case reducer function
// export const { increment, decrement, incrementByAmount } = counterSlice.actions
export const { addLocation } = locationSlice.actions

export const selectLocations = (state: RootState) => state.location

export default locationSlice.reducer