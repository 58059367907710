import Moment from 'moment';

export interface ILocation {
    id: string;
    dateTime: string;
    lat: number;
    lng: number;
}

export function NewILocation(lat:number, lng:number):ILocation {
          
    return {id:"", dateTime:currentFormatedTime(), lat:lat, lng:lng}
}

export function NewILocationWithID(id:string, lat:number, lng:number):ILocation {
    return {id:id, dateTime:currentFormatedTime(), lat:lat, lng:lng}
}

function currentFormatedTime():string {
    Moment.locale('zh-tw');
    var dt = new Date();
    return Moment(dt).format('YYYY-MM-DD hh:mm:ss')
}