import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import type { RootState } from '../store/store';

export interface IUser {
  email: string
  familyName: string
  givenName: string
  profilePicture: string
  authID: string
  name: string
}

const initialState: IUser = {email:"", familyName:"", givenName:"", profilePicture:"", authID:"", name:""};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUserInfo: (state, action: PayloadAction<IUser>) => (
      action.payload
    ),
    clearUserInfo: (state) => (
      initialState
    ),
  },
})

// Action creators are generated for each case reducer function
// export const { increment, decrement, incrementByAmount } = counterSlice.actions
export const { setUserInfo, clearUserInfo } = userSlice.actions

export const selectUser = (state: RootState) => state.user

export default userSlice.reducer