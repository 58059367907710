import * as React from 'react'

import {useState, useEffect} from 'react';
import { useAppDispatch, useAppSelector } from '../../redux/store/hook';
import { setUserInfo, selectUser, IUser } from '../../redux/slices/user';
import { useLoginMutation } from '../../redux/api/auth';
import { useUploadLocationMutation } from '../../redux/api/location';
import { LocationTable } from './LocationTable';

import LinearProgress from '@material-ui/core/LinearProgress';
import { Button } from '@material-ui/core';
import GpsFixedIcon from '@material-ui/icons/GpsFixed';
import { ILocation, NewILocation, NewILocationWithID } from '../../models/location';
import { selectLocations, addLocation } from '../../redux/slices/locations';
import Alert from '@material-ui/lab/Alert';
import { 
    Collapse,
    Grid,
    Box,
} from '@material-ui/core';
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    acquireLocationBtn: {
        height: 100,
    },
}));

export function MyLocation() {
    
    const classes = useStyles();
    
    const dispatch = useAppDispatch()

    const currentUser = useAppSelector(selectUser)
    console.log(`dump current user: `, currentUser)

    const [apiUploadLocation, { isLoading:isApiUploadLocationLoading }] = useUploadLocationMutation()
  
    const [isLocating, setIsLocating] = useState(false);
    const [currentLocation, setCurrentLocation] = useState<ILocation>({id:"", dateTime:"", lat:0.0, lng:0.0})
    const [isNotifying, setIsNotifying] = React.useState(false);

    // const [locations, setLocations] = useState([] as ILocation[]);
    
    const locations = useAppSelector(selectLocations)
    console.log("DUMP redux locations: ", locations)
  
    const createData = (id: string, dateTime:string, lat:number, lng:number):ILocation => {
      return {id, dateTime, lat, lng};
    }

    const uploadLocation2 = (lat:string, lng:string) => {
    //   const requestOptions = {
    //     method: 'POST',
    //     headers: { 'Content-Type': 'application/json', 'Sanbao-Auth': currentUser.authID },
    //     body: JSON.stringify({ latitude: lat, longitude: lng })
    //   };
  
    //   fetch("/api/location", requestOptions)
    //     .then(res => {
    //       if (!res.ok) {
    //         throw new Error(`Network response=${res.status} was not ok`);
    //       }
    //       return res.json()
    //     })
    //     .then(data => {
    //       Moment.locale('zh-tw');
    //       var dt = new Date();
    //       const locations = this.state.locations.slice().concat(
    //         this.createData(data['id'], Moment(dt).format('YYYY-MM-DD hh:mm:ss'),lat, lng)
    //       );
    //       this.setState({locations: locations});
    //     })
    //     .catch(e => {
    //       console.log("uploadLocation API error=", e)
    //     })
    }
  
    const getLocation = () => {
      if (!navigator.geolocation) {
        console.log('Geolocation is not supported by your browser');
      } else {
        console.log('Locating...');
        setIsLocating(true)
        
    
        navigator.geolocation.getCurrentPosition((position) => {
        //   var latestLocation:ILocation = NewILocation(0, 0);

          if(currentUser) {
            console.log("uploading location info")

            apiUploadLocation({lat: position.coords.latitude, lng: position.coords.longitude})
            .unwrap()
            // .then(resp => resp.json())
            .then(fulfilled => {
                console.log("RESP: ", fulfilled)
                const latestLocation = NewILocationWithID(fulfilled.id, position.coords.latitude, position.coords.longitude)
                setCurrentLocation(latestLocation)
                dispatch(addLocation(latestLocation))
                setIsLocating(false)
                setIsNotifying(true)
                setTimeout(() => setIsNotifying(false),3000)
            })

          } else {
            console.log("user not login, skip uploading location")
            const latestLocation = NewILocationWithID((Math.random()*1000000).toString(10).substr(0,4), position.coords.latitude, position.coords.longitude)
            setCurrentLocation(latestLocation)
            dispatch(addLocation(latestLocation))
            setIsLocating(false)
          }

          

        },
        () => {
          console.log('Unable to retrieve your location');
        },
        {
          enableHighAccuracy: true
        }
        );
      }
    }
  
    // componentDidMount() {
  
    //   if(this.props.user.isLogined) {
    //     const requestOptions = {
    //       method: 'GET',
    //       headers: { 'Content-Type': 'application/json', 'Sanbao-Auth': this.props.user.authID },
    //     };
  
    //     fetch("/api/location", requestOptions)
    //     .then(res => {
    //       if (!res.ok) {
    //         throw new Error(`Network response=${res.status} was not ok`);
    //       }
    //       return res.json()
    //     })
    //     .then(data => {
          
    //       // console.log("got user locations: ", data)
  
    //       const remoteData = data.map( (dd) => {
    //         var gg = this.createData(dd.id, Moment(dd.created_at).format('YYYY-MM-DD hh:mm:ss'), dd.lat, dd.lng)
    //         return gg
    //       })
  
    //       const locations = this.state.locations.slice().concat(remoteData)
          
    //       this.setState({locations: locations});
  
    //     })
    //     .catch(e => {
    //       console.log("uploadLocation API error=", e)
    //     })
  
    //   } else {
    //     console.log("user not login, skip uploading location")
    //   }
    // }
  
    return (
    <div>
        { isLocating && 
        <LinearProgress />
        }


        <Collapse in={isNotifying}>
            <Alert severity="success">紀錄成功</Alert>
        </Collapse>
        

        {/* <Grid container spacing={3}>
        <Grid item xs={6}>
            
        </Grid>

        <Grid item xs={6}>
        
        </Grid>

        </Grid> */}
        
        <Grid container spacing={1} style={{ margin: 5 }}>
            <Grid item xs={12} sm={6}>
                <Box bgcolor="text.primary" color="primary.contrastText" p={2}>
                緯度：{currentLocation.lat}
                </Box>
            </Grid>
            <Grid item xs={12} sm={6}>
                <Box bgcolor="text.primary" color="secondary.contrastText" p={2}>
                經度：{currentLocation.lng}
                </Box>
            </Grid>
        </Grid>

        <Button 
        variant="contained"
        size="large"
        color="primary"
        fullWidth
        onClick={getLocation}
        className={classes.acquireLocationBtn}
        startIcon={<GpsFixedIcon />}
        >
        取得 GPS 定位
        </Button>
        
        <LocationTable locations={locations}></LocationTable>
    </div>
    )
    
  }